@media (max-width: 600px) {
  .apex-charts {
    height: 200px; /* Adjust the height for smaller screens */
  }

  .portfoliobtnstyle,
  .portfoliobtnactive {
    font-size: 12px;
    padding: 5px 10px;
  }

  .chart-title,
  .chart-axis-label {
    font-size: 16px;
  }
}
#csvFileInput {
  display: none;
}
