.AnalyticsProperty {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AnalyticsProperty > .heading {
  color: #5d596c;
  font-family: "Roboto";
  font-size: 10px;
}

.AnalyticsProperty > .value {
  color: #414446;
  font-family: "IBM Plex Serif";
  font-size: 14px;
}
