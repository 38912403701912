@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

/* ----- Components ----- */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.requireField {
  border: 2px solid red !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.disable-scroll {
  overflow: hidden;
}

.Header1Box {
  width: 100%;
  height: 50px;
  background: #f8f2f2;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Header2Box {
  width: 97%;
  height: 70px;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d0cbc3;
  padding-bottom: 5px;
}

.LeftSide {
  display: flex;
  align-items: center;
}

.RightSide {
  align-self: flex-end;
}

.SynergyIcon {
  height: 35px;
}

.CustomSearchBar {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: 7px;
}

.Header2Text {
  color: var(--text-txt-primary, #112333);
  font-family: Lora;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.6px;
}

.Headerimage {
  height: 34px;
  left: 20px;
  position: absolute;
  top: 8px;
  width: 135px;
}

/* Panel */

.progressbar {
  width: 100%;
  height: 30px;
  border-radius: 50px;
  border: 1px solid #000;
  margin-bottom: 10px;
  text-align: center;
  background-color: #fff;
}

.progressbarfill {
  height: 100%;
  border-radius: 50px;
  background-color: green;
  transition: width 0.5 ease-out;
  color: #fff;
}

.blue {
  border-radius: 30px;
}

.red {
  border-radius: 30px !important;
}

.btnstyle {
  border: 1px solid #9e9ca6;
  background-color: white;
  /* padding: 10px; */
  color: #9e9ca6;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 7.12px;
  border-radius: 2px;
  width: 24.425px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.btnactive {
  border: 1px solid #a35bb1;
  color: #a35bb1;
  /* padding: 10px; */
  margin-right: 7.12px;
  border-radius: 2px;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  width: 24.425px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}

/* Channel */
.title {
  color: #5d596c !important;
  font-family: Roboto;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 5px !important;
}

.tableData {
  color: #414446 !important;
  font-family: IBM Plex Serif !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: capitalize;
  padding: 15px !important;
}

.table {
  padding: 32px 22px;
}

.tbody tr {
  border-bottom: 1px solid #d3d3d3 !important;
  height: 70px;
}

.tableHead {
  color: #9853a6 !important;
  text-align: center !important;
  font-family: 'Roboto Condensed' !important;
  font-size: 11px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  /* padding: 1px 20px !important; */
  margin-left: 30px;
  transform: rotate(270deg);
  display: table-caption !important;
  border-bottom: 0px !important;
  /* display: block !important; */
  /* vertical-align: middle; */
  /* display: table-cell !important; */
}

.leadingTableRow {
  height: 75px;
  writing-mode: tb;
  transform: rotate(180deg);
  word-wrap: break-word;
  color: #9853a6 !important;
  text-align: center !important;
  font-family: 'Roboto Condensed' !important;
  font-size: 11px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  padding: 7px 16px !important;
}

.thead {
  text-align: end;
}

.tablerow {
  height: 80px !important;
}

/* dashboard */
body {
  background-color: #ffffff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
}

h1 {
  margin: 0;
}

main {
  margin: 20px;
  text-align: center;
}

p {
  margin-bottom: 20px;
}

a {
  color: #000000;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

i {
  color: orange;
  font-style: normal;
}

.dashboard-embed {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* position: relative; */
}

.dashboard-subtitle {
  color: #414446;
  font-family: IBM Plex Serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin: 0.3%;
}

/* Customer data intelligant */
.modal {
  width: 834px;
  /* height: 371px; */
  position: fixed;
  /* top: 35px;
    left: 299px; */
  z-index: 99;
}

.wrapper {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #00000080;
  z-index: 9;
}

.requireField {
  border: 2px solid red !important;
}

/* intigration */

* {
  box-sizing: border-box;
}

/* Spin Animation */
.animate-spin {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

/* header */
.MuiStepLabel-labelContainer span {
  font-size: x-small;
}

.header {
  margin-top: 2.5vh;
  color: #414446;
  font-family: IBM Plex Serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
}

.css-1fu2e3p-MuiPaper-root-MuiDialog-paper {
  /* background-color: #fff; */
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  margin: 32px;
  position: relative;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* Map Fields */
.inputs {
  display: flex;
  flex-direction: column;
  width: 60vh;
  height: 55vh;
  border: solid 1px #ebe8f1;
  border-radius: 6px;
  padding: 2vh;
}

.inputs-content {
  margin-top: 2vh;
  margin-bottom: 2vh;
  padding-right: 2vh;
  overflow: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(75, 0, 55, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(241, 118, 237, 0.5);
}

.csv-field {
  height: 3vh;
  text-align: left;
  color: var(--col-8, #000);
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  justify-content: 'left';
}

.target-field .MuiSelect-root {
  border-radius: 6px;
  border: 1px solid #ebe8f1;
  background: #fff;
}

/* Finalize */
.text-heading {
  height: 3vh;
  margin-top: 1vh;
  color: var(--col-8, #000);
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}

.main-screen {
  width: 65vh;
  height: 80vh;
  gap: 2vh;
}

.grid-content {
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-left: 0.8vh;
  display: relative;
}

.content {
  border-radius: 5px;
  border: 1px solid #ebe8f1;
  background: #f6f5f8;
  height: 84px;
  flex-shrink: 0;
  margin-top: 2vh;
}

.grid-content-key {
  color: var(--col-6, #5d596c);
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.grid-content-value {
  color: var(--col-9, #8e0000);
  font-family: IBM Plex Serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-top: -10px;
}

.text-subheading {
  color: var(--col-6, #5d596c);
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1.5vh;
  text-align: right;
}

.checkbox-container {
  display: flex;
  gap: 20px;
  margin-top: 2vh;
  margin-bottom: 1vh;
}

label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

input[type='checkbox'] {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  appearance: none;
  background-color: #fff;
  border: 2px solid var(--col-3, #e08029);
  border-radius: 4px;
}

input[type='checkbox']:checked {
  background-color: var(--col-3, #e08029);
}

.checkbox-label {
  color: var(--col-3, #e08029);
  font-family: 'Noto Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 173.7%;
  text-transform: capitalize;
}

/* Main Body */
.body-background {
  background-color: #f6f5f7;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100rem;
}

.Grid {
  background-color: #f6f5f7;
  border: 5px none;
  height: 1622px;
  position: relative;
  width: 100%;
}

.overlap {
  background: linear-gradient(180deg, rgb(62, 0, 74) 0%, rgb(43.45, 4.46, 51) 100%);
  box-shadow: 0px 4px 7px #00000017;
  height: 652px;
  left: 0;
  position: absolute;
  top: 67px;
  width: 1440px;
}

/* Footer */
.footer-style {
  color: #852497;
  font-family: 'Roboto', Helvetica;
}

.footer-background {
  background-color: #f6f5f7;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  width: 100vw;
}

.footer-detail-background {
  background-color: #2a0930;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  width: 100vw;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', Helvetica;
}

.footer-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto', Helvetica;
}

/* Main Header */

.mainHeader {
  background-color: 'white';
  /* flex-direction: row; */
  width: 100%;
  border: 1px solid;
  height: 7vh;
}

.image {
  height: 32px;
  left: 24px;
  position: relative;
  /* top: 14px;
  width: 135px; */
}

.hamburger-menu {
  display: none;
}

.toggle {
  width: 100%;
}

/* intigrateapp */
.AnalyticsProperty {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AnalyticsProperty > .heading {
  color: #5d596c;
  font-family: 'Roboto';
  font-size: 10px;
}

.AnalyticsProperty > .value {
  color: #414446;
  font-family: 'IBM Plex Serif';
  font-size: 14px;
}

/* Rules */

.workflow-logic-chip-label {
  max-width: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  color: rgb(0, 200, 83);
  background-color: rgba(185, 246, 202, 0.376);
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
}

.workflow-logic-chip-operator {
  max-width: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  color: black;
  background-color: white;
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
}

.workflow-logic-chip-value {
  max-width: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  color: rgb(103, 58, 183);
  background-color: rgb(237, 231, 246);
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
}

.workflow-logic-chip-label:hover {
  color: rgb(237, 231, 246);
  background-color: rgb(0, 200, 83);
}

.workflow-logic-chip-value:hover {
  color: rgb(237, 231, 246);
  background-color: rgb(103, 58, 183);
}

/* QuearyBuilder */

.workflow-logic-chip-label {
  max-width: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  color: rgb(0, 200, 83);
  background-color: rgba(185, 246, 202, 0.376);
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
}

.workflow-logic-chip-operator {
  max-width: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  color: black;
  background-color: white;
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
}

.workflow-logic-chip-label {
  max-width: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  color: rgb(0, 200, 83);
  background-color: rgba(185, 246, 202, 0.376);
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
}

.workflow-logic-chip-operator {
  max-width: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  color: black;
  background-color: white;
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
}

.workflow-logic-chip-value {
  max-width: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  color: rgb(103, 58, 183);
  background-color: rgb(237, 231, 246);
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
}

.workflow-logic-chip-label:hover {
  color: rgb(237, 231, 246);
  background-color: rgb(0, 200, 83);
}

.workflow-logic-chip-value:hover {
  color: rgb(237, 231, 246);
  background-color: rgb(103, 58, 183);
}

.workflow-logic-chip-value {
  max-width: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  color: rgb(103, 58, 183);
  background-color: rgb(237, 231, 246);
  margin: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
}

.workflow-logic-chip-label:hover {
  color: rgb(237, 231, 246);
  background-color: rgb(0, 200, 83);
}

.workflow-logic-chip-value:hover {
  color: rgb(237, 231, 246);
  background-color: rgb(103, 58, 183);
}

/* Onboarding step */
.contant {
  display: none;
}

.active-contant {
  display: block;
}

.active-step {
  border: 1px solid #d0b6d6;
  width: 70%;
  padding: 30px 77px 30px 25px;
  background-color: #efe7ef;
  border-radius: 6px;
  position: relative;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;

  border-left: 60px solid green;
}

.step {
  width: 70%;
  padding: 30px 77px 30px 25px;
}

.step img {
  width: 40px;
}

.active-step .theme2 {
  color: #a35bb1;
}

.active-step:after {
  content: '';
  /* padding-left: 0.5em;
    color: black;
  
position: absolute;
top: 0%;
right: 0%;} */
  display: block;
  width: 0;
  height: 0;
  right: -18px;
  top: 68px;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;

  border-left: 18px solid #a35bb1;
}

/* ragister */
.custom-phone-input input {
  /* height: 25px; */
  /* Set the desired height */
}

@media (max-width: 450px) {
  input[type='password'] {
    width: 100% !important;
    height: 25px !important;
    margin-right: 10px !important;
  }
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 13px 14px !important;
}

.Mui-selected {
  color: #a35bb1 !important;
}

.MuiTabs-indicator {
  background: #a35bb1 !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #9853a6 !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 0px 9px !important;
}

.custom-button-root {
  width: 146px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #852598;
}

.custom-button-label {
  /* color: #fff; */
  text-align: center;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  width: 67px;
}

.custom-button-root-disabled {
  width: 146px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--col-12, #852598);
}

.campaign-container {
  display: grid;
  gap: 10px;
  margin: 20px;
}

.LeftSide {
  width: '10px';
  height: '10px';
  display: flex;
  align-items: center;
}

.headerText {
  color: var(--col-8, #000);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.normalText {
  color: var(--col-6, #5d596c);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.staredText {
  color: var(--col-6, #5d596c);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 151.188%;
  text-transform: capitalize;
}

.inputBox-root {
  width: 419px;
  border-radius: 6px;
  border: 1px solid #ebe8f1;
  background: #fff;
}

.inputBox-root .MuiOutlinedInput-root {
  color: #838383;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.inputBox-root .MuiInputLabel-root {
  color: #838383;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.mainBackGround {
  background-color: #f6f5f8;
  gap: 10px;
}

.contentBox {
  border-radius: 6px;
  border: 1px solid #ebe8f1;
  background: #fff;
  margin: 30px;
  padding: 30px;
}

.stepLabel {
  color: #a35bb1;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.titleText {
  color: #414446;
  font-family: 'IBM Plex Serif';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

.customHR {
  border: 1px solid #ebe8f1;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.goodFor {
  display: inline-block;
  border-radius: 4px;
  background: rgba(163, 91, 177, 0.1);
  padding: 8px;
  width: auto;
  margin-top: 6px;
  margin-bottom: 6px;
  color: var(--col-8, #000);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.Header2Box {
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  padding: 30px;
  justify-content: space-between;
}

.GridComp {
  margin: 25px;
  width: 600px;
}

.grid-content-key {
  color: #9853a6;
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px;
  text-align: left;
}

.grid-content-value {
  color: #414446;
  font-family: IBM Plex Serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* text-transform: capitalize; */
}

.selectAll {
  color: var(--col-14, #a35bb1);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 151.188%;
  /* 21.166px */
}

.predictiveKPI {
  color: #158c6f;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 2px;
}

.summaryBox {
  border-radius: 5px;
  border: 1px solid #ebe8f1;
  background: #f6f5f8;
  width: 385px;
  min-height: 258px;
  flex-shrink: 0;
}

.summaryText {
  color: var(--col-8, #000);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 254.187%;
  /* 35.586px */
  text-transform: capitalize;
}

.DropBox {
  border-radius: 5px;
  border: 2px dashed #b9b4c3;
  background: #f6f5f8;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.DragText {
  color: var(--col-6, #5d596c);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BrowseText {
  color: #a35bb1;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.custom-card {
  border-radius: 5px;
  border: 1px solid #ebe8f1;
  background: #fff;
  box-shadow: 0px 8px 20px 0px rgba(165, 92, 92, 0.06);
  width: 150px;
  height: 80px;
}

.date-input {
  border: none;
  background: transparent;
  color: var(--col-8, #000);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 151.188%;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #ebe8f1;
  background: #fff;
  padding: 1px;
  width: 130px;
  height: 31px;
  padding: 2px;
  cursor: pointer;
}

.card-number {
  color: var(--col-6, #000);
  font-family: IBM Plex Serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.pop-up-window {
  width: 70%;
  border-radius: 6px;
  border: 1px solid #ebe8f1;
  background: #fff;
}

.manageAllCampaignText {
  color: #414446;
  font-family: IBM Plex Serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}

h1 {
  font-family: IBM Plex Serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 4px;
  margin-top: 4px;
  color: var(--col-12, #414446);
}

h2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
  margin-top: 4px;
  color: var(--col-12, #414446);
}

h3 {
  color: var(--col-8, #112333);
  /* font-family: Roboto; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 149.453%;
  margin-bottom: 4px;
  margin-top: 4px;
  text-transform: capitalize;
}

h4 {
  color: var(--col-12, #414446);
  /* font-family: Roboto; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
  margin-top: 4px;
}

hr {
  border: 1px solid #ebe8f1;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.tracking {
  width: 65%;
  border: 1px solid #ebe8f1;
  padding: 5px;
  border-radius: 6px;
  color: #5d596c;
  margin-right: 15px;
  font-size: 12px;
}

.CardHeading {
  color: #414446;
  font-family: IBM Plex Serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  /* 166.667% */
}

.reducedAutocomplete .MuiAutocomplete-popper {
  margin-top: 0 !important;
}

.matched-offer {
  border-radius: 4px;
  background: #f0eaf1;
  padding: 1px;
  padding-right: 15px;
  padding-left: 15px;
  color: var(--Col-8, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 40px;
  display: flex;
  align-items: center;
}

.portfoliobtnstyle {
  border: 1px solid #9e9ca6;
  background-color: white;
  /* padding: 10px; */
  color: #9e9ca6;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7.12px;
  border-radius: 3px;
  height: 25px;
}

.portfoliobtnactive {
  border: 1px solid #a35bb1;
  color: #a35bb1;
  /* padding: 10px; */
  margin-right: 7.12px;
  border-radius: 3px;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;

  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebaricon {
  color: #9853a6 !important;
}

.MuiFormControlLabel-label {
  font-size: 13px !important;
}

.viewbutton {
  border: solid 2px;
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-weight: 700;
}

.card {
  margin-top: 1rem;
  height: 280px;
  width: 500px;
  position: relative;
  border-radius: 0.25rem;
}

.cardFront,
.cardBack {
  box-sizing: border-box;
  border-radius: 0.25rem;
  height: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  transition: transform 0.5s ease;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #ebf4ff;
}

.cardBack {
  transform: perspective(1000px) rotateY(180deg);
}

.cardBack.flipped {
  transform: perspective(1000px) rotateY(0deg);
}

.cardFront {
  transform: perspective(1000px) rotateY(0deg);
}

.cardFront.flipped {
  transform: perspective(1000px) rotateY(-180deg);
}

.multiline-ellipsis {
  display: inline-block !important;
  -webkit-box-orient: horizontal !important;
  -webkit-line-clamp: 3 !important;
  /* start showing ellipsis when 3rd line is reached */
  /* white-space: pre-wrap !important; let the text wrap preserving spaces */
}

.apexcharts-bar-series .apexcharts-bar-area:hover {
  cursor: pointer;
}
.iconMEnu {
  padding: 7px;
  cursor: pointer;
}
.Custompagination {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
}
.CustomPageItem {
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 100%;
}
.CustomPageItem:hover{
  background-color: #bd87c7;
}
.CustomActivePagination {
  background-color: #852497;
}
.CustomActivePagination .CustomPageLink {
  color: #ffffff;
}
.CustomPageLink:hover {
  text-decoration: none;
}
